/** @jsx jsx */
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import get from 'lodash/get';
import Container from '@fify.at/gatsby-theme-base/src/components/Grid/Container';
import { jsx } from 'theme-ui';
import Brand from '@fify.at/gatsby-theme-base/src/components/Header/components/Brand';
import HeaderWrapper from '@fify.at/gatsby-theme-base/src/components/Header/components/HeaderWrapper';
import Navigation from '@fify.at/gatsby-theme-base/src/components/Header/components/Navigation';
import NavItem from '@fify.at/gatsby-theme-base/src/components/Header/components/NavItem';
import * as styles from '../Header.styles';
import Icon from '@fify.at/gatsby-theme-base/src/components/Icon/Icon';
import Nav from 'react-bootstrap/Nav';
import { NavLink as linkStyles } from '@fify.at/gatsby-theme-base/src/components/Header/Header.styles';
import Button from '@fify.at/gatsby-theme-base/src/components/Button';

export default function DefaultHeader({
  children,
  menus = [],
  siteTitle,
  lang,
  appearance,
  attachment,
  fluid,
  ...rest
}) {
  const { navigation, logo } = useHeaderQuery({ lang });
  return (
    <HeaderWrapper
      {...rest}
      sx={{
        ...styles.Header,
        ...styles.getHeaderAppearance(appearance),
        ...styles.getHeaderAttachment(attachment),
      }}
    >
      <Container sx={{ position: 'relative', '&&': { padding: 3 } }}>
        {logo && (
          <Brand>
            <Image fixed={logo} alt={siteTitle} />
          </Brand>
        )}
        {navigation && (
          <Navigation>
            {navigation.map(item => (
              <NavItem key={item.slug || item.url} {...item} />
            ))}
          </Navigation>
        )}
        <div
          sx={{ display: ['none', null, null, 'block'], fontSize: '0.8rem' }}
        >
          <Button href="tel:+436601151400" variant="dark">
            Kostenlose Service-Hotline <br />
            <Icon icon="fa-phone-alt" variant="fal" /> +43 660 11 51 400
          </Button>
        </div>
      </Container>
    </HeaderWrapper>
  );
}

/**
 * Extract the data for the header from the site metadata (defined in `gatsby-config.js`).
 * @param {Object} props The configuration.
 * @param {String} props.lang The language.
 * @returns {{
 *      leftNav: [{slug: String, title: String}],
 *      rightNav: [{slug: String, title: String}],
 *      logo: {src: String, srcSet: String}
 *    }} The navigation and logo for the header.
 */
function useHeaderQuery({ lang }) {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "logo" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          header {
            navigation {
              lang
              position
              slug
              url
              icon
              title
              type
              items {
                slug
                title
              }
            }
          }
        }
      }
    }
  `);
  const {
    file,
    site: {
      siteMetadata: {
        header: { navigation: allNav },
      },
    },
  } = data;
  // Extract the navigation lists.
  const isCorrectLang = item => (lang ? item.lang && item.lang === lang : true);
  const navigation = allNav.filter(item => isCorrectLang(item));
  const logo = get(file, 'childImageSharp.fixed');
  return { navigation, logo };
}
