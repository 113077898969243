export * from '@fify.at/gatsby-theme-base/src/components/Header/Header.styles';

export const Header = {
  padding: 0,
  '.container': { paddingX: 0 }, // Remove the Container padding (see maxmakler-website-bemassung-1600.pdf)
};

export const Brand = {
  '&&&': {
    position: 'relative !important', // Allow the navigation to center.
    marginRight: [3, null, 0],
  },
  '.gatsby-image-wrapper': {
    height: '75px !important',
  },
  img: {
    // position: ['relative !important', null, null, 'absolute !important'],
    height: '75px !important',
    objectFit: 'contain !important',
    objectPosition: 'left !important',
    padding: ['10px', null, null, '0'], // Allow the navigation to center.
  },
};

export const Nav = {
  //  marginX: 'auto', // Center the navigation.
  marginTop: 4,
  marginBottom: 0,
  marginX: 'auto',
  // display: 'block',
};

export const NavLink = {
  '&&&': {
    paddingX: [4, null, null, '10px', 3],
    paddingY: 4,
    color: 'text',
    ':hover': {
      color: 'primary',
    },
  },
  '&[aria-current]:not(false)': { color: 'accent' },
};

export const NavDropdown = {
  '.dropdown-menu': {
    borderRadius: 0,
    border: '0',
  },

  '.dropdown-toggle.nav-link': {
    fontSize: [2, null, null, '14px', 1],
    paddingBottom: [2, 0],
    fontWeight: 'bold',
    borderColor: 'transparent',
  },

  '.dropdown-item': {
    paddingY: 3,
  },

  '.dropdown-item.active, .dropdown-item:active': {
    backgroundColor: 'muted',
  },

  // Style the NavDropdown inner link like the NavLink.
  '> a': { ...NavLink, color: 'text' },
  '&&&': {
    '> a': {
      color: 'text',
      paddingX: [4, null, null, '10px', 3],
      ':hover': {
        color: 'primary',
        borderColor: 'accent_complementary',
      },
      ':active': {
        backgroundColor: 'transparent !important',
      },
    },
  },
};
