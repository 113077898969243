/** @jsx jsx */
import { useFooterQuery } from '@fify.at/gatsby-theme-base/src/components/Footer/Footer';
import FooterNavItem from '@fify.at/gatsby-theme-base/src/components/Footer/FooterNavItem';
import {
  Col,
  Container,
  Row,
} from '@fify.at/gatsby-theme-base/src/components/Grid/index';
import { Footer as BaseFooter, jsx } from 'theme-ui';
import * as styles from './Footer.styles';
import SocialMedia from '@fify.at/gatsby-theme-base/src/components/SocialMedia/SocialMedia';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import logo from '../../../../../content/assets/logo.png';

export default function Footer({ lang, children, fluid = false, ...rest }) {
  const { navigation } = useFooterQuery({ lang });
  const siteTitle = 'footerLogo';

  return (
    <BaseFooter sx={styles.Footer}>
      <Section container="none" sx={styles.Section} {...rest}>
        <Container>
          <Row>
            <Col xs="12" sm="12" md="6" lg="6">
              <div
                sx={{
                  marginBottom: 3,
                }}
              >
                <img
                  sx={{ width: '100px', marginBottom: 2 }}
                  src={logo}
                  alt="WirKaufenDeinHaus"
                />
              </div>
              <div sx={{ marginBottom: 4 }}>
                <em>WirkaufendeinHaus.at ist ein Service der</em>
                <br />
                BUYMYHOME24 GMBH
                <br />
                Gewerblicher An- und Verkauf von Immobilien
                <br />
                Obere Landstrasse 9, 3500 Krems
                <br />
                Tel: <a href="tel:+436601151400">+43 660 11 51 400</a>
                <br />
                E-Mail:{' '}
                <a href="mailto:office@wirkaufendeinhaus.at">
                  office@wirkaufendeinhaus.at
                </a>
              </div>
              <div sx={{ marginBottom: [4, null, 0] }}>
                © WirkaufendeinHaus.at 2020
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6">
              <SocialMedia />
              {/*<a href="https://www.wko.at/">*/}
              {/*  <img sx={{ width: '100px' }} src={WKO_logo} alt="WKO" />*/}
              {/*</a>*/}
              {navigation && (
                <nav
                  sx={{
                    ...styles.FooterNavigation,
                    variant: 'layout.Footer.Navigation',
                  }}
                >
                  <ul>
                    {navigation.slice(0, 5).map(item => (
                      <FooterNavItem
                        sx={styles.FooterNavItem}
                        key={item.slug || item.url}
                        {...item}
                      />
                    ))}
                  </ul>
                </nav>
              )}
              {navigation && (
                <nav
                  sx={{
                    ...styles.FooterNavigationInline,
                    variant: 'layout.Footer.FooterNavigationInline',
                  }}
                >
                  <ul>
                    {navigation.slice(5, 10).map(item => (
                      <FooterNavItem
                        sx={styles.FooterNavItem}
                        key={item.slug || item.url}
                        {...item}
                      />
                    ))}
                  </ul>
                </nav>
              )}
            </Col>
          </Row>
        </Container>
      </Section>
    </BaseFooter>
  );
}
