/** @jsx jsx */
import { jsx } from 'theme-ui';
import { I18nProvider } from '@lingui/react';
import { setupI18n } from '@lingui/core';
import favicon64 from '../../../../../content/assets/favicon.png';
import Layout from '@fify.at/gatsby-theme-base/src/components/Layout/Layout';
import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby-plugin-modal-routing';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import Icon from '@fify.at/gatsby-theme-base/src/components/Icon/Icon';
import { graphql, useStaticQuery } from 'gatsby';

const i18n = setupI18n();
// i18n.load('de', {
//   localeData: { ...listingsDe.localeData, ...catalogDe.localeData },
//   messages: { ...listingsDe.messages, ...catalogDe.messages },
// });
i18n.activate('de');

export default function MainLayout({ lang, children, location, ...rest }) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <I18nProvider i18n={i18n}>
      <Layout {...rest}>
        <Helmet>
          <script src="https://kit.fontawesome.com/cb5e2ccb8a.js" />
          <link rel="shortcut icon" href={favicon64} />
        </Helmet>
        <div
          sx={{
            position: 'fixed',
            display: ['flex'],
            flexWrap: 'wrap !important',
            flexFlow: ['row', 'column'],
            right: ['auto', 0],
            top: ['auto', '200px'],
            bottom: [0, 'auto'],
            width: ['100%', 'auto'],
            zIndex: 3,
          }}
        >
          {mounted && (
            <Link
              to="/online-immobilienbewertung/"
              asModal
              sx={{
                display: 'block',
                width: ['60%', 'auto'],
                float: ['left', 'none'],
                backgroundColor: 'accent',
                color: 'white',
                paddingLeft: 3,
                paddingRight: 2,
                paddingY: 2,
                ':hover': {
                  backgroundColor: 'secondary',
                  color: 'white',
                },
              }}
              onClick={function() {
                if (typeof fbq !== 'undefined') {
                  fbq('track', 'Content anzeigen');
                }
              }}
            >
              <i
                sx={{ marginRight: 2 }}
                className="fad fa-calculator fa-lg"
              ></i>{' '}
              Immobilie online bewerten!
            </Link>
          )}
          {mounted && (
            <Link
              to="/terminbuchung/"
              asModal
              sx={{
                width: ['40%', 'auto'],
                marginLeft: 'auto',
                backgroundColor: 'primary',
                color: 'white',
                paddingLeft: 3,
                paddingRight: 2,
                paddingY: 2,
                ':hover': {
                  backgroundColor: 'secondary',
                  color: 'white',
                },
              }}
              onClick={function() {
                if (typeof fbq !== 'undefined') {
                  fbq('track', 'Content anzeigen');
                }
              }}
            >
              <i
                sx={{ marginRight: 2 }}
                className="fad fa-calendar-alt fa-lg"
              ></i>{' '}
              Termin vereinbaren!
            </Link>
          )}
          {location && (
            <SocialMediaShareButtons
              siteUrl={siteMetadata.siteUrl}
              location={location}
            />
          )}
        </div>
        {children}
      </Layout>
    </I18nProvider>
  );
}

function SocialMediaShareButtons({ siteUrl, location }) {
  return (
    <div
      sx={{
        width: ['100%', 'auto'],
        minWidth: ['150px'],
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: ['space-around', 'space-between'],
        backgroundColor: 'black',
        color: 'white',
        paddingLeft: 3,
        paddingRight: 2,
        paddingY: 2,
        ':hover': {
          backgroundColor: 'secondary',
          color: 'white',
        },
        '.SocialMediaShareButton:hover': {
          display: 'inline',
          color: 'accent',
          cursor: 'pointer',
        },
      }}
    >
      <FacebookShareButton url={`${siteUrl}${location.pathname}`}>
        <Icon variant="fab fa-md" icon="fa-facebook-f" />
      </FacebookShareButton>
      <LinkedinShareButton url={`${siteUrl}${location.pathname}`}>
        <Icon variant="fab fa-md" icon="fa-linkedin-in" />
      </LinkedinShareButton>
      <TwitterShareButton url={`${siteUrl}${location.pathname}`}>
        <Icon variant="fab fa-md" icon="fa-twitter" />
      </TwitterShareButton>
      <WhatsappShareButton url={`${siteUrl}${location.pathname}`}>
        <Icon variant="fab fa-md" icon="fa-whatsapp" />
      </WhatsappShareButton>
    </div>
  );
}

export const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
