import makeVariant from '@fify.at/gatsby-theme-base/src/components/makeVariant';
export * from '@fify.at/gatsby-theme-base/src/components/Button/Button.styles';

/**
 * Gets the button's style.
 * @param {String} variant The button variant.
 */
export const getVariant = makeVariant(
  /* Any default styles here. */
  {
    // e.g.: color: 'primary',
    borderRadius: 0,
  },
  {
    default: {},

    primary: {
      color: 'onPrimary',
      backgroundColor: 'primary',
      borderColor: 'primary',
    },

    onDark: {
      color: 'primary',
      backgroundColor: 'red',
      borderColor: 'onPrimary',
    },
  }
);

export const ToggleButton = {
  ':hover': {
    backgroundColor: 'primary',
    borderColor: 'secondary',
    color: 'onPrimary',
  },
  ':not(:disabled):not(.disabled).active, :not(:disabled):not(.disabled):active': {
    backgroundColor: 'primary',
    color: 'onPrimary',
    boxShadow: 'none',
    borderColor: 'secondary',
    ':focus': { boxShadow: 'none' },
  },
};

export const MultiselectOption = {
  width: '100%',
  fontSize: '0.9rem',
  borderColor: 'gray',
  backgroundColor: 'transparent',
  color: 'text',
  textAlign: 'left',
  borderRadius: 0,
  ':hover': {
    backgroundColor: 'primary',
    borderColor: 'secondary',
    color: 'onPrimary',
  },
  ':not(:disabled):not(.disabled).active, :not(:disabled):not(.disabled):active': {
    '&&&&&': {
      backgroundColor: 'transparent',
      borderColor: 'secondary',
    },
    color: 'black',
    boxShadow: 'none',
    borderColor: 'secondary',
    ':focus': { boxShadow: 'none' },
  },
};

export const ButtonGroup = {
  color: 'secondary',
  borderRadius: 0,
};

export const ButtonToolbar = {};

export const ToggleButtonGroup = { color: 'secondary' };
