import { Footer as FooterOrig } from '@fify.at/gatsby-theme-base/src/components/Footer/Footer.styles';

export * from '@fify.at/gatsby-theme-base/src/components/Footer/Footer.styles';

export const Footer = {
  ...FooterOrig,
  fontSize: '13px',
  color: 'text',
  backgroundColor: '#f2f4f9',
  marginTop: 8,

  a: {
    color: 'primary',
  },
};

export const Section = {
  width: '100%',
  marginBottom: [6, 0],
};

export const Separator = {
  paddingTop: 5,
  borderTop: '4px solid',
  borderColor: 'primary',
};

export const Heading = {};
export const FooterNavigationWrapper = {};

export const FooterNavigation = {
  ul: {
    listStyle: 'none',
    textAlign: ['left', null, 'right'],
    marginTop: 3,
    marginBottom: 3,
    flexFlow: 'column',
    alignSelf: 'flex-end',
  },
};

export const FooterNavigationInline = {
  ul: {
    display: 'flex',
    listStyle: 'none',
    justifyContent: ['flex-start', null, 'flex-end'],
    marginTop: 4,
    flexFlow: 'row',
    alignSelf: 'flex-end',
    li: {
      marginLeft: 3,
    },
  },
};

export const FooterNavItem = {
  fontSize: '13px',
  color: 'text',
  paddingY: 0,
  paddingLeft: 0,
  ':hover': {
    color: 'accent',
  },

  a: {
    color: 'text',
    ':hover': {
      color: 'accent',
    },
  },
};

export const Map = {
  width: '100%',
};
