import makeVariant from '@fify.at/gatsby-theme-base/src/components/makeVariant';

// The Section base styles.
export const Section = {
  position: 'relative',
};

// The variants for the individual containers
export const sectionContainers = {
  default: {
    paddingY: 7,
    paddingX: [0, 0, 0, 5],
  },
  fluid: {},
  none: {},
  small: {
    paddingY: 7,
    paddingX: [0, 0, 0, 5],
    maxWidth: '920px',
    marginX: 'auto',
  },
};

export const getSectionVariant = makeVariant(Section, sectionContainers);

export const Overlay = {
  paddingY: 5,
  paddingX: [0, 0, 0, 5],
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  color: 'onDark',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  '> *': { pointerEvents: 'auto' },
};
