export const SocialMediaWrapper = {
  display: 'flex',
  justifyContent: 'flex-end',

  a: {
    marginLeft: 3,
    fontSize: ['26px'],
    color: 'inherit',
  },
};
